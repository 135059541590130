// src/pages/AboutPage.js
import React from 'react';

const AboutPage = () => (
  <div>
    <h2>About Page</h2>
    <p>This page provides information about our project and goals.</p>
  </div>
);

export default AboutPage;
