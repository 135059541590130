// src/pages/ContactPage.js
import React from 'react';

const ContactPage = () => (
  <div>
    <h2>Contact Page</h2>
    <p>Feel free to reach out to us through the contact form below.</p>
  </div>
);

export default ContactPage;
